<template>
  <div class="content">
    <div class="head-title">松果Cam隐私政策</div>
    <div class="" style="marginBottom:30px">我们的隐私政策于2022年2⽉21⽇生效。</div>
    <div class="" style="marginBottom:30px">我们的隐私政策于2023年2⽉25⽇更新。</div>
    <div class="">请花⼀些时间熟悉我们的隐私政策，如果您有任何问题，请联系我们。</div>

    <div class="title">关于我们</div>
    <div class="">
      北京北纬蜂巢物联科技有限公司（以下简称“蜂巢物联”或“我们”）⼗分重视您的隐私。本隐私政策（以下简称“本政策”）在制定时充分考虑到您的需求。您将全面了解到我们个⼈信息收集和使用惯例，同时确保您最终能够控制您提供给蜂巢物联的个⼈信息，这⼀点至关重要。
    </div>
    <div class="">
      松果Cam智能摄像机（以下简称“
      <label class="import-title">松果智能摄像机</label>
      ”或“摄像机”）由
      <label class="import-title">北京北纬蜂巢物联科技有限公司</label>
      提供的⼀款软硬件结合的产品，即本政策提及的智能硬件设备。
    </div>
    <div class="">
      松果Cam是⼀款与智能硬件设备绑定后可对智能硬件设备进行管理的手机软件（以下简称“APP”）。您可以在APP上查看智能硬件设备实时拍摄和历史拍摄的视频。同时可以通过APP实现图像和视频事件报警、声⾳事件报警、语⾳对讲等功能。
    </div>
    <div class="title">关于本隐私政策</div>
    <div class="">
      欢迎您使用我们的产品和服务！我们深知个⼈信息对您的重要性，也深知为您的个⼈信息提供有效保护是我们业务健康可持续发展的基⽯。感谢您对我们产品和服务的使用和信任！您的信赖对我们⾮常重要，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个⼈信息，致力于保护您的个⼈信息安全可控。基于此，服务提供者蜂巢物联制定本政策，帮助您充分了解在您使用松果CamAPP管理智能硬件设备时，我们如何收集、使用、存储、分享和保护您的个⼈信息和智能硬件设备的信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式，以便您更好地做出适当的选择。
    </div>
    <div class="">
      在您使用APP前，请您务必仔细阅读和理解本政策，特别应重点阅读我们以粗体标识的条款，在确认充分理解并同意后使用我们的产品或服务。⼀旦您开始使用松果智能摄像机的产品和服务，即表⽰您已充分理解并同意本政策，并同意我们按照本政策收集、使用、储存和分享您的相关信息。如对本政策内容有任何疑问、意见或建议，您可通过本政策提供的联系方式与我们联系。
    </div>
    <div class="import-title">本隐私政策将帮助您了解以下内容</div>
    <div class="import-title">1 我们收集哪些信息以及如何使用信息</div>
    <div class="import-title">2 我们如何使用Cookie和同类技术</div>
    <div class="import-title">3 我们如何共享、转让、公开披露您的个⼈信息</div>
    <div class="import-title">4 我们如何保存和保护您的个⼈信息</div>
    <div class="import-title">5 您的权利</div>
    <div class="import-title">6 未成年⼈个⼈信息保护</div>
    <div class="import-title">7 本政策如何更新</div>
    <div class="import-title">8 联系我们</div>

    <div class="title">1 我们收集哪些信息以及如何使用信息？</div>
    <div class="second-title">1.1 我们收集哪些信息</div>
    <div class="">
      为了向您提供我们的服务，我们需要您提供使用该服务所必需的信息。我们只会收集为实现具体、特定、明确及合法的目的所必需的信息，并且确保不会对这些信息进行与上述目的不相符的进⼀步处理。您有权自行选择是否提供我们请求的信息，但多数情况下，明确及合法的目的所必需的信息，并且确保不会对这些信息进行与上述目的不相符的进⼀步处理。您有权自行选择是否提供我们请求的信息，但多数情况下，
      <label class="import-title">如果您拒绝提供相应信息，您将无法正常使用我们的服务，也无法回应您遇到的问题。</label>
    </div>
    <div class="">根据您选择的服务，我们可能收集以下信息中的⼀种或多种：</div>
    <div class="third-title">1.1.1 您主动提供给我们的个人信息</div>
    <div class="">
      我们会根据您选择的服务，收集您在使用相应服务时需要提供的个⼈信息。例如，若您使用松果云存储服务，您可能会上传松果云存储的相关资料。若您创建帐号，您可能会提供您的
      <label class="import-title">手机号、手机验证码/密码、性别、帐号安全设置等相关信息</label>
      。若您参与市场促销活动，您可能会向我们提供您的
      <label class="import-title">昵称、电子邮件地址、照⽚、视频等相关信息</label>
      。若您参与我们的活动或获奖，您可能会向我们提供您的
      <label class="import-title">姓名、手机号码和地址等相关信息。</label>
    </div>
    <div class="third-title">1.1.2 实现松果智能摄像机的核⼼功能</div>
    <div class="">
      •
      <label class="import-title">通过注册账号收集的信息。</label>
      当您在手机上安装APP后，需要在APP上注册或登录松果账号方可使用。当您注册松果账号时，您需要提供手机号后用来接收验证码进行注册登录。您可通过“我的”修改您的头像、昵称等信息。您注销松果账号时，我们将停⽌使用并删除上述信息或对您的个⼈信息进行匿名化处理，法律法规另有规定的除外。
    </div>
    <div class="">
      •
      <label class="import-title">绑定智能硬件设备。</label>
      绑定智能硬件设备后，您可通过APP查看智能硬件设备实时拍摄的视频画面和历史录像视频。您亦可保存视频到本地或通过第三方应用分享给好友。当您通过第三方分享视频给好友时，我们不会获取任何关于您第三方应用的任何信息（包括相关账户信息）。您的手机在连接WiFi网络并在APP上登录且已购买“云存储”服务或手机中插有内存卡的情况下，可通过APP的“云录像”和“卡录像”中查看并管理智能硬件设备拍摄的历史视频记录。当您使用“云录像”服务时，在智能硬件设备开启使用并连接WiFi的情况下，我们会将智能硬件设备拍录的所有视频加密上传到蜂巢物联的服务器，实现同步到不同设备端和对视频⽂件进行保护。若您使用“卡录像”服务，智能硬件设备拍摄的视频仅保存在您的本地，不会上传蜂巢物联服务器。
      <label class="import-title">
        请您注意，智能硬件设备拍录的视频中可能会记录您、您家⼈或第三方个体的面部特征，为保护各方隐私信息和尊重各方隐私权益，请您在安装、使用和通过APP管控智能硬件设备时，通过“我的->通用设置->关于”阅读并了解《松果智能摄像机免责协议》，充分理解您和我们双方的责任、义务和权利。智能硬件设备拍录的视频是您和家⼈的重要隐私信息，若上传，我们会严格加密并存储到我们的服务器，不会将录像视频用于他处，亦不会共享给第三方。
      </label>
    </div>
    <div class="">
      •
      <label class="import-title">查看智能硬件设备拍录的视频画面。</label>
      在您登录APP后，您可通过松果智能摄像机附带的激活流程引导，进行您设备的连接和绑定。为实现APP和智能硬件设备的绑定，我们需要获取您的位置信息(用于获取WiFi列表)、WiFi网络信息、智能硬件设备和手机开关机及连接网络的状态、智能硬件设备型号、手机运营商、手机操作系统和版本、手机型号、手机本地存储空间。上述信息会上传到蜂巢物联的服务器，以实现阻⽌⾮您本⼈的其他身份访问、管理智能硬件设备，更好地保护您的信息的安全性。您可通过“设备->移除该摄像机”实现对智能硬件设备的解绑、删除与智能硬件设备相关的所有您的个⼈信息。
    </div>
    <div class="">
      •
      <label class="import-title">管控智能硬件设备。</label>
      当您绑定智能硬件设备后，您可通过APP中的“摄像机->设置”对摄像机的功能服务进行管控，包括摄像机命名、服务场景、画面变动报警、识别⼈脸报警、异常声⾳报警、设
      备相册相关的推送消息。当您开启智能硬件设备的在家免打扰功能时，您需要授权我们获取您手机的MAC地址，用于确认您的手机与智能硬件设备处于同⼀Wifi网络环境下。您设置和提供的信息会
      上传到我们的服务器，作为对智能硬件设备的指令，实现对智能硬件设备的管控。
    </div>
    <div class="">
      •
      <label class="import-title">摄像机的安全助手功能。</label>
      此功能在确保摄像机开启的状态下，提供摄像机拍录视频、消息通知以及声⾳驱离。您可在“摄像机->设置->安全助手设置”中对安全助手的功能进行设置，包括告警时间段、智能功能管理，消息类型管理以及铃声管理。为及时收到“安全助手”的消息提醒，您需要开启系统通知权限和允许后台运行。您的设置信息和提供的相关信息会上传到我们的服务器，实现对摄像机发出相关指令。您还可通过“摄像机设置->安全助手”关闭安全助手功能，通过“设置->告警消息推送”来关闭相关消息提醒功能。关闭后我们将不再为您提供智能看家服务或消息提醒，但不会影响您使用其他功能。
      APP内部集成了fcm厂商推送、honor厂商推送、华为推送SDK、魅族推送SDK、oppo推送SDK、vivo推送SDK、小米推送SDK，会自动根据您的手机品牌进行推送。
    </div>

    <div class="third-title">1.1.3 实现松果智能摄像机的附加功能</div>
    <div class="">
      附加功能是指除核⼼功能和第三方功能以外的服务功能，需要您自主选择开启使用。您未开启的功能，我们将不会收集您的个⼈信息。同时，您可以通过下⽂所提及的关闭路径关闭附加功能。关闭后，我们将不再收集和使用您的个⼈信息，且不会影响您使用APP的核⼼功能。
    </div>
    <div class="">
      •
      <label class="import-title">截屏录像。</label>
      此功能提供在您查看摄像机拍录的视频时，可对视频进行截屏。您可在“设备->相册”或手机本地的“相册”中查看截屏的录像和照⽚。您的截屏录像会上传到服务器，实现不同设备端同步查看。我们不会将您的截屏录像用于他处，亦不会共享给第三方。
    </div>
    <div class="">
      •
      <label class="import-title">异动监测。</label>
      我们会将智能硬件设备拍录下来的⼈物的面部特征进行识别，实现陌生⼈异动监测提醒和对您或者家⼈的财产进行安全保护。您可查看最近两天出现在智能硬件设备前，被拍摄到的⼈物列表和相关录像。同时，您可对频繁出现在镜头前的⼈像进行备注，实现更好地对陌生⼈异动进行监测。
    </div>
    <div class="">
      •
      <label class="import-title">回放。</label>
      设备将在监控到异常状况后自动开始录制视频，录制的内容将在回放中滚动存储，您可以设置是否开启全程录像，全程录像开启后可能造成内存浪费，影响您的使用体验。
    </div>
    <div class="">
      •
      <label class="import-title">共享智能硬件设备。</label>
      （1）您可在分享功能中输⼊好友手机号后生成共享智能硬件设备的⼆维码，您的好友通过扫描⼆维码来共享您的智能硬件设备，当您通过分享功能将设备分享给好友时，我们不会获取您账户的任何信息。（2）您还可以通过手机号邀请您的好友作为智能硬件设备的管理成员，需要您授权我们获取“联系⼈权限”，实现通过通讯录列表选择您要共享智能硬件设备的联系⼈。我们仅本地读取您的通讯录列表，供您选择共享联系⼈，不会将您的通讯录信息上传到服务器，亦不会共享给第三方。若您拒绝授权，您将不能通过通讯录选择联系⼈，但不影响您通过手动输⼊联系⼈手机号。联系⼈受邀后，仅可随时查看智能硬件设备实时拍录的画面和所有的报警消息记录。您可在“设置->设备分享/场景分享”中管理成员列表。需要提醒的是，分享或类似功能将使您的相应个⼈信息在您指定范围内公开，您须谨慎决定是否使用，并自行对相关后果负责。
    </div>
    <div class="">
      •
      <label class="import-title">购买云存储。</label>
      云存储为您提供随时通过APP观看和保存智能硬件设备拍摄的历史视频的功能服务。为使用该功能，您需要购买云存储。在您选择购买并支付智能硬件设备的云存储套餐服务时，可通过第三方支付方式（包括微信、支付宝、苹果支付）实现支付。我们仅获取您购买的商品名称、订单编号、订单⾦额，以及支付状态，以实现为您提供相关云存储服务，我们不会获取第三方支付账号的任何信息。
    </div>
    <div class="third-title">1.1.4 设备权限调用</div>
    <div class="">
      APP在提供服务过程中，会调用您手机的⼀些设备权限。以下是APP调用权限对应的业务功能、调用权限的目的以及调用权限前向您询问的情况。
    </div>
    <div class="">
      您可以在设备的设置功能中选择关闭部分或全部权限，从⽽拒绝APP收集相应的个⼈信息。在不同设备中，权限显⽰方式及关闭方式可能有所不同，具体请参考设备及系统开发方说明或指引。
    </div>
    <div class="">
      请您注意，您开启任⼀权限即代表您授权我们可以收集和使用相关个⼈信息来为您提供对应服务，您关闭任⼀权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个⼈信息，也将停⽌为您提供相对应的服务。但是，您关闭权限的操作不会影响此前基于您的授权所进行的信息收集及使用。
    </div>
    <div class="grid-table">
      <div>
        <label class="head-th col-span"></label>
        <label class="head-th">A</label>
        <label class="head-th">B</label>
        <label class="head-th">C</label>
        <label class="head-th">D</label>
        <label class="head-th">E</label>
      </div>
      <div>
        <label class="col-span">1</label>
        <label>设备权限</label>
        <label>对应业务功能</label>
        <label>调用权限目的</label>
        <label>是否询问</label>
        <label>用户可否关闭权限</label>
      </div>
      <div v-for="(item, index) in limitOptions" :key="index">
        <label class="col-span">{{ index + 2 }}</label>
        <label>{{ item.authName }}</label>
        <label>{{ item.funcName }}</label>
        <label>{{ item.purpose }}</label>
        <label>{{ item.isNotice }}</label>
        <label>{{ item.canClose }}</label>
      </div>
    </div>
    <div class="third-title">1.1.5 改善我们的产品和服务</div>
    <div class="">
      •
      <label class="import-title">产品改善和优化。</label>
      为不断改进我们的产品、排除并减少产品崩溃情况以及为您提供更优质的产品功能服务，我们会通过手机系统的公用接⼝获取您手机及所绑定的智能硬件设备的以下信息：手机的国际移动身份码（IMEI）、网络设备地址（MAC）、运营商、手机型号、手机系统版本号、系统编号、系统ID号、屏幕分辨率、上网类型、手机中APP的名称、版本号、包名、APP使用时间和频率、APP崩溃⽇志信息。用于统计我们产品的用户数量、分析产品的使用状况、排查崩溃原因以及优化产品功能。这些信息不涉及您的个⼈身份相关信息。
    </div>
    <div class="">
      反馈和资讯。您可以通过APP的“我的->帮助与反馈->问题反馈/使用咨询”向我们反馈或咨询您使用APP中遇到的问题。当您反馈问题或咨询时，您需要提供您的联系方式（微信号、手机号）、选择问题类型、硬件类型、问题的详细描述和附图。我们会通过分析前段中提到的信息找出问题原因并努力解决问题。您在反馈意见中所提供的联系方式，只会用于我们的客服与您沟通和反馈，除征得您明确同意和法律明确规定外，我们不会向任何第三方提供您提供的联系信息。
    </div>
    <div class="third-title">1.1.6 征得授权同意的例外</div>
    <div class="">根据相关法律法规规定，以下情形中收集您的个⼈信息无需征得您的授权同意：</div>
    <div class="">• 与国家安全、国防安全有关的。</div>
    <div>• 与公共安全、公共卫生、重⼤公共利益有关的。</div>
    <div>• 与犯罪侦查、起诉、审判和判决执行等有关的。</div>
    <div>• 出于维护个⼈信息主体或其他个⼈的生命、财产等重⼤合法权益但⼜很难得到您本⼈同意的。</div>
    <div>• 所收集的个⼈信息是您自行向社会公众公开的。</div>
    <div>• 从合法公开披露的信息中收集个⼈信息的，如合法的新闻报道、政府信息公开等渠道。</div>
    <div>• 根据您的要求签订合同所必需的。</div>
    <div>• 用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障。</div>
    <div>•
      为合法的新闻报道所必需的。学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个⼈信息进行去标识化处理的。
    </div>
    <div class="">• 法律法规规定的其他情形。</div>
    <div class="second-title">1.2 这些个⼈信息将会被如何使用</div>
    <div class="">
      我们收集个⼈信息的目的在于向您提供产品和/或服务，并且保证我们遵守适用的相关法律、法规及其他规范性⽂件。这包括：
    </div>

    <div class="">
      •
      <label class="import-title">提供、处理、维护、改善、开发我们的产品或提供给您的服务。</label>
      例如注册登录、激活、验证、售后、客户支持和⼴告宣传。
    </div>
    <div class="">
      •
      <label class="import-title">用于防丢失和防欺诈等目的的安全保障。</label>
      协助识别用户、验证身份等。我们仅在满⾜以下两个条件时将您的信息用于反欺诈的目的：绝对有必要。将该信息用于评估符合蜂巢物联保护用户及服务的合法利益。
    </div>
    <div class="">
      •
      <label class="import-title">处理您关于设备与服务的提问或请求。</label>
      例如解答用户询问、发送系统及应用程序的消息通知、管理您参加的抽奖等活动。
    </div>
    <div class="">
      •
      <label class="import-title">进行相关的推⼴活动。</label>
      例如提供推⼴与促销的资料和更新。除⾮适用的法律另有规定，如果您不再希望接收某些类别的推⼴信息，您可以通过该信息中提供的退出方式（如邮件底部的取消订阅链接）来拒绝此类服务。请参阅下方“您的权利”。
    </div>
    <div class="">
      •
      <label class="import-title">内部目的，</label>
      如数据分析、研究和开发与我们产品或服务的使用相关的统计信息，以更好地改进我们的产品或服务。
    </div>
    <div class="">
      •
      <label class="import-title">优化设备的性能。</label>
      例如分析应用程序的内存使用情况或应用的CPU利用率。
    </div>
    <div class="">
      •
      <label class="import-title">储存并维护与您相关的信息，用于我们的业务运营</label>
      （例如业务统计）或
      <label class="import-title">履行法律义务。</label>
    </div>
    <div class="">
      •
      <label class="import-title">基于蜂巢物联的正当利益处理数据。</label>
      正当利益包括使我们能够更有效地管理和运营我们的业务，并提供我们的产品和服务。保护我们的业务、系统、产品、服务和客户的安全（包括出于预防损失和反欺诈的目的）。内部管理。遵守内部规章和流程。以及本政策中描述的其他正当利益。例如，为了保证我们服务的安全性，帮助我们进⼀步了解应用的性能状况，我们可能会记录相关信息，如您的使用频率、崩溃⽇志信息、整体使用情况、性能数据和应用来源等。为了防⽌未经授权的⼚商解锁设备，我们可能会收集松果帐号、操作电脑的序列号和IP地址、您手机的序列号和设备信息。
    </div>
    <div class="">
      •
      <label class="import-title">提供不需要与我们的服务器通信的本地服务。</label>
      例如在您的设备端使用笔记服务。
    </div>
    <div class="">
      •
      <label class="import-title">其他征得您同意的目的。</label>
      关于我们如何使用您的信息（其中可能包含个⼈信息），下面提供了更多详细⽰例：
    </div>
    <div class="">
      •
      <label class="import-title">激活、注册您购买的松果产品或服务。</label>
    </div>
    <div class="">
      •
      <label class="import-title">创建及维护您的松果帐号。</label>
      您通过移动设备创建松果帐号时提供的个⼈信息可用于建⽴您个⼈松果帐号和资料页。
    </div>
    <div class="">
      •
      <label class="import-title">处理您的订购单。</label>
      与电子商务订单相关的信息可用于处理订购单和相关的售后服务，包括客户支持和重新发货。此外，订单号将用于校验物流合作伙伴的订单和包裹的实际交付。收件⼈信息，包括姓名、地址、电话号码和邮政编码，将用于送货。电子邮件地址用于向您发送包裹跟踪信息。购买物品的清单用于打印发票，并使您能够确定包裹中的物品。
    </div>
    <div class="">
      •
      <label class="import-title">诊断激活失败。</label>
      相关的地理位置信息用于评估物联卡激活失败，以识别该服务的网络运营商，并通知网络运营商。
    </div>
    <div class="">
      •
      <label class="import-title">记录照⽚的地理位置信息。</label>
      您能够在拍照时记录地理位置信息。这⼀信息将在相册中可见，并且保存在照⽚的属性信息中。
    </div>
    <div class="">
      •
      <label class="import-title">通过数据、硬件和软件分析改善用户体验。</label>
      某些选择性加⼊功能，例如用户体验计划，允许APP分析用户如何使用设备与其他服务的数据，以改善用户体验，例如发送崩溃报告。APP还将进行硬件和软件的分析，以进⼀步改善用户体验。
    </div>
    <div class="">
      •
      <label class="import-title">收集用户反馈。</label>
      您选择提供的反馈对帮助我们改进服务⾮常重要。为了及时处理您提供的反馈，我们可能会使用您所提供的个⼈信息与您联系，并保留记录用于解决问题及改进服务。
    </div>
    <div class="">
      •
      <label class="import-title">发送通知。</label>
      我们可能会将您的个⼈信息用于发送重要通知，例如有关订单的通知和我们的条款、条件和政策的变更。由于此类信息对您和我们之间的沟通至关重要，不建议您拒绝接收此类信息。
    </div>

    <div class="title">2 我们如何使用 Cookie 和同类技术</div>
    <div class="">
      Cookie是支持服务器端（或者脚本）在网站或客户端上存储和检索信息的⼀种机制，是互联网中的通用常用技术。Cookie同类技术是可用于Cookie类似用途的其他技术，如Beacon、网站信标、嵌⼊式脚本等。
    </div>
    <div class="">
      我们使用Cookie、标签和脚本等技术，这些技术用于分析趋势、管理网站、追踪您的网站活动并收集关于整个用户群体的统计信息。我们会收到通过以上技术收集的个体或汇总的分析报告。这些技术帮助我们更好地了解您的行为，使我们了解您浏览我们App的哪些部分，衡量⼴告和网络搜索的效果并加以改善。
    </div>
    <div class="">
      •
      <label class="import-title">⽇志⽂件。</label>
      和⼤部分网站⼀样，我们收集特定信息并保存在⽇志⽂件中。此类信息可能包括互联网协议 (IP) 地址、浏览器类型、互联网服务供应商
      (ISP)、引用/退出页面、操作系统、⽇期/时间戳和/或点击流数据。我们不会把自动收集的⽇志数据与我们收集的其他信息进行关联。
    </div>
    <div class="">
      •
      <label class="import-title">移动分析。</label>
      在某些移动应用中，我们使用分析类Cookie来收集有关访问者如何使用我们网站的信息。这类cookie收集的信息包括您使用该应用程序的频率、该应用程序内发生的事件、累计使用、性能数据以及应用程序发生崩溃的情况。我们不会将存储于分析软件内的信息与您在移动应用程序中提交的任何个⼈信息相关联。
    </div>

    <div class="title">3 我们如何共享、转让、公开披露您的个⼈信息</div>
    <div class="second-title">3.1 共享</div>
    <div class="">我们不会将任何个⼈信息出售给第三方。</div>
    <div class="">
      我们有时可能会向第三方（定义见下⽂）共享您的个⼈信息，以便提供或改进我们的产品或服务，包括根据您的要求提供产品或服务。下面提供了有关数据共享的具体信息。
    </div>
    <div class="third-title">3.1.1 您主动选择的共享</div>
    <div class="">
      在获得您的明确同意或主动选择的情况下，与您指定的第三方共享您授权/请求范围内的信息。例如，您使用松果帐号快捷登录第三方网站或者APP。
    </div>
    <div class="third-title">3.1.2 与关联公司间共享信息</div>
    <div class="import-title">
      为便于我们与关联公司共同向您提供部分服务，我们可能不定时与关联公司分享产品或服务相关的数据，您的个⼈信息可能会与我们的关联公司和/或其指定的服务提供商共享。我们只会共享必要的个⼈信息，且受本政策的约束，如果我们共享您的敏感个⼈信息或关联公司改变个⼈信息的使用及处理目的，将再次征求您的授权同意。
    </div>
    <div class="third-title">3.1.3 与第三方服务提供商与业务合作伙伴共享</div>
    <div class="import-title">
      为保证向您提供本政策所述的产品和服务，我们可能会在必要时与我们的第三方服务提供商和业务合作伙伴共享您的个人信息。
    </div>
    <div class="">
      这包括我们的物流供应商、数据中心、数据存储设施、客户服务供应商、广告和营销服务供应商、消息推送服务商以及其他业务合作伙伴。这些第三方可能代表蜂巢物联或出于本政策的一项或多项目的处理您的个人信息。
      <label class="import-title">我们保证仅出于正当、合法、必要、特定、明确的目的共享为您提供服务所必要的个人信息。</label>
      第三方服务提供商也可能拥有其子处理者。为提供成效衡量、分析和其他商业服务，我们还可能以汇总的形式与第三方（例如我们网站上的广告商）共享信息（非个人信息）。我们使用所掌握的信息来帮助广告主和其他合作伙伴评估他们广告和服务的成效及覆盖情况，并了解使用他们服务的人群类型以及人们如何与其网站、应用和服务互动。我们也可能与其共享我们服务的一般使用趋势，例如购买某些产品或从事某些交易的特定人群中的客户数量。
    </div>
    <div class="import-title"></div>
    <div>A、极光SDK</div>
    <div>使用目的：用于实现手机APP消息推送</div>
    <div>收集个人信息类型：设备信息 - 设备标识符（IMEI、IDFA、Android
      ID、MAC、OAID、IMSI等相关信息）、应用信息（应用崩溃信息、通知开关状态、软件列表等相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息）
    </div>
    <div>B、极光认证</div>
    <div>极光认证收集使用的个人信息包括：①SDK认证相关请求结果：用于判断开发者是否违规使用，以及监控线上一键认证稳定性。②设备信息：用于识别唯一用户，检测和防止欺诈活动，帮助开发者规避不必要的损失。
      ③网络信息与位置信息：优化SDK与极光服务器的网络连接请求，保证服务的稳定性和连续性。分析认证请求失败原因。
      ④手机号码：一键认证场景下，由APP开发者主动提供，APP开发者通过极光的通道获得电信运营商的认证结果并将结果展示给终端用户；一键登录场景下，在获得终端用户授权后我们会从运营商处获得终端用户sim卡对应的电话号码，并将电话号码返还给APP开发者进行号码校验，完成一键登录。
      ⑤APP安装列表信息：为了保护您的账号安全，实现反作弊功能，我们将收集您的该类信息，您可以选择开启或关闭这项服务
    </div>
    <div>极光隐私政策：<a class="link" target="_blank" href="https://www.jiguang.cn/license/privacy">https://www.jiguang.cn/license/privacy</a>
    </div>
    <div>C、友盟SDK</div>
    <div>服务类型：统计分析，故障排查，性能监控优化</div>
    <div>收集个人信息类型：设备信息（IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM卡IMSI/ICCID/地理位置等）</div>
    <div>隐私权政策链接：
      <a class="link" target="_blank" href="https://www.umeng.com/page/policy">https://www.umeng.com/page/policy</a>
    </div>

    <div>D、小米推送 SDK</div>
    <div>使用目的：推送消息</div>
    <div>使用场景：在小米手机终端推送消息时使用</div>
    <div>第三方主体：北京小米移动软件有限公司</div>
    <div>设备标识符（如 Android ID、OAID、GAID）、设备信息</div>
    <div>隐私权政策链接：
      <a class="link" target="_blank" href="https://dev.mi.com/console/doc/detail?pId=1822">https://dev.mi.com/console/doc/detail?pId=1822</a>
    </div>

    <div>E、阿里推送SDK</div>
    <div>使用目的：用于实现手机APP消息推送</div>
    <div>收集个人信息类型：设备信息 - 设备标识符（IMEI、IDFA、Android
      ID、MAC、OAID、IMSI等相关信息）、应用信息（应用崩溃信息、通知开关状态、软件列表等相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息）
    </div>
    <div>官网链接：
      <a class="link" target="_blank" href="https://www.aliyun.com/product/cps">https://www.aliyun.com/product/cps</a>
    </div>

    <div>F、OPPO 推送 SDK</div>
    <div>使用目的：推送消息</div>
    <div>使用场景：在 OPPO 手机终端推送消息时使用</div>
    <div>收集个人信息类型：设备标识符（如 IMEI、ICCID、IMSI、Android ID、GAID）、应用信息（如应用包名、版本号和运行状态）、网络信息（如 IP 或域名连接结果，当前网络类型）
    </div>
    <div>隐私权政策链接：
      <a class="link" target="_blank" href="https://open.oppomobile.com/wiki/doc#id=10288">https://open.oppomobile.com/wiki/doc#id=10288</a>
    </div>

    <div>G、vivo 推送 SDK</div>
    <div>使用目的：推送消息</div>
    <div>使用场景：在 vivo 手机终端推送消息时使用</div>
    <div>收集个人信息类型：设备标识符（如 IMEI、ICCID、IMSI、Android ID、GAID）、应用信息（如应用包名、版本号和运行状态）、网络信息（如
      IP
      或域名连接结果，当前网络类型）
    </div>
    <div>隐私权政策链接：
      <a class="link" target="_blank" href="https://www.vivo.com.cn/about-vivo/privacy-policy">https://www.vivo.com.cn/about-vivo/privacy-policy</a>
    </div>

    <div>H、支付宝SDK</div>
    <div>使用目的：用于唤起支付宝APP实现手机支付</div>
    <div>隐私权政策链接：
      <a class="link" target="_blank" href="https://opendocs.alipay.com/common/02kiq3">https://opendocs.alipay.com/common/02kiq3</a>
    </div>

    <div class="third-title">3.1.4 其他</div>
    <div class="">
      根据法律法规规定、法律程序、诉讼和/或公共机构和政府部门依法提出的要求，蜂巢物联可能有必要披露您的个⼈信息。如果就国家安全、执法或具有公众重要性的其他事宜⽽⾔，披露是必须的或适当的，我们也可能会披露关于您的信息。
    </div>
    <div class="">
      为了执行我们的条款或保护我们的业务、权利、资产或产品，或保护用户，或对于达成以下目的⽽⾔（检测、预防并解决欺诈、对产品的未授权使用、对我们条款或政策的违反或其他有害或⾮法活动）披露是合理必要的，我们也可能会披露关于您的信息。（在某些情况下，在当地数据保护法律明确许可的范围内，蜂巢物联可能会在未经您同意的情况下收集、使用或披露您的个⼈信息。）这可能包括向公共或政府机构提供信息。与第三方合作伙伴交流有关您账户可靠性方面的信息，以防⽌在我们产品内外所进行的欺诈、违规等其他有害行为。
    </div>
    <div class="">此外，我们可能与下列⼈员共享您的个⼈信息：</div>
    <div class="">• 我们的会计师、审计师、律师或类似的顾问，当我们要求他们提供专业建议时。</div>
    <div class="">• 投资者以及其他相关第三方，如果发生实际或潜在的出售或其他公司交易，且与北纬集团内实体相关时。</div>
    <div class="">• 本政策中详述的其他第三方或以其他方式通知您的第三方，如经您授权就特定信息进行披露。</div>

    <div class="second-title">3.2 转让</div>
    <div class="">除以下情形外，蜂巢物联不会将您的信息转让给任何主体：</div>
    <div class="">• 获得您的明确同意。</div>
    <div class="">
      •
      如果蜂巢物联参与合并、收购或出售其全部或部分资产，可能会影响到您的个⼈信息，我们将通过电子邮件和/或在我们网站上或其他适当方式发布醒目通知，告知对您个⼈信息的所有权、使用权方面的任何变化，以及您可能拥有的关于您个⼈信息的任何选择。
    </div>
    <div class="">• 在本政策中已解释的或以其他方式通知您的情况下。</div>

    <div class="second-title">3.3 公开披露</div>
    <div class="">在以下情况下，蜂巢物联可能会公开披露您的个⼈信息：</div>
    <div class="">• 当我们需要公布促销、⽐赛或抽奖活动的获胜者时，我们只会公布有限的信息。</div>
    <div class="">• 基于法律或合理依据的公开披露：包括法律法规规范、法律程序、诉讼或应政府主管部门要求。</div>
    <div class="import-title">
      请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他⼈的个⼈信息甚至敏感个⼈信息。请您更加谨慎地考虑并作出决定。
    </div>

    <div class="title">4 我们如何保存和保护您的个⼈信息</div>
    <div class="second-title">4.1 蜂巢物联的安全措施</div>
    <div class="">
      我们致力于保护您的个⼈信息的安全。为了防⽌未经授权的访问、披露或其他类似⻛险，我们落实了法律规定的物理、电子和管理措施流程，以保障我们从您的移动设备上收集的信息的安全。我们将确保依据适用的法律保护您的个⼈信息。
    </div>
    <div class="">
      例如，当您访问松果帐号时，您可以选择我们的验证程序来更好地保证安全性，我们也强烈建议您使用此方式。当您通过APP向我们的服务器发送或收取信息时，我们确保使用传输层安全协议
      (TLS)和其他适当的算法对其进行加密。
    </div>
    <div class="">
      您的个⼈信息全都被储存在安全的服务器上，并在受控设施中受到保护。我们依据重要性和敏感性对您的信息进行分类，并且保证您的个⼈信息具有相应的安全等级。同样，我们对以云为基础的数据存储设有专门的访问控制措施，我们定期审查信息收集、储存和处理实践，包括物理安全措施，以防⽌任何未经授权的访问和使用。
    </div>
    <div class="">
      我们将对第三方服务提供商与业务合作伙伴进行尽职调查以确保他们有能力保护您的个⼈信息。我们还将通过实施适当的合同限制，并在必要时进行审计及评估，来检查第三方是否采用了适当的安全标准。
    </div>
    <div class="">
      我们会举办安全和隐私保护培训课程、测试，以加强员⼯对于保护个⼈信息重要性的认识。我们将采取所有可行且法律要求的措施保护您的个⼈信息。然⽽，您应当意识到互联网的使用并不是完全安全的，因此，我们不能保证在通过互联网双向传输时任何个⼈信息的安全性或完整性。
    </div>
    <div class="">
      我们将根据适用的数据保护法规的要求处理个⼈信息的泄露，在必要时，及时通知相关的监管机构及数据主体相关的数据泄露事件。
    </div>
    <div class="second-title">4.2 您如何保护个⼈信息</div>
    <div class="">
      您可以通过不向任何⼈（除⾮此⼈经您正式授权）披露您的登录密码或帐号信息，您可以为您的账号设置唯⼀的密码，以防⽌其他网站密码泄露危害您在APP的帐号安全。无论何时，请不要向任何⼈（包括自称是APP客服的⼈⼠）透露您收到的验证码。无论您以松果帐号用户的身份登录松果网站，尤其是在他⼈的计算机或公共互联网终端上登录时，您总应当在会话结束后注销登出。
    </div>
    <div class="">
      蜂巢物联不对因您未能保持个⼈信息的私密性⽽导致第三方访问您的个⼈信息进⽽造成的安全疏漏承担责任。尽管有上述规定，如果发生其他任何互联网用户未经授权使用您帐号的情况或其他任何安全漏洞，您应当⽴即通知我们。您的协助将有助于我们保护您个⼈信息的私密性。
    </div>

    <div class="second-title">4.3 保留政策</div>
    <div class="">
      我们基于本政策所述的信息收集的目的所必需的期间，或者遵守适用法律的要求保留个⼈信息。具体保留时长可参见特定服务或相关产品页面。个⼈信息在完成收集目的，或在我们确认过您的删除或注销申请后，或我们终⽌运营相应产品或服务后，我们将停⽌保留，并做删除或匿名化处理。在可能的情况下，我们会说明我们已识别的个⼈信息类别、类型或字段的保存期限。在决定上述保存期限时，
    </div>
    <div class="">我们考虑了以下因素：</div>

    <div class="">• 个⼈信息的数量、性质及敏感性</div>
    <div class="">• 因未经授权使用或披露⽽造成伤害的⻛险</div>
    <div class="">• 我们处理个⼈信息的目的，以及为达到这些目的，我们需要该特定信息多长的时间</div>
    <div class="">• 个⼈信息尽可能保持准确与及时更新的时间长短</div>
    <div class="">• 个⼈信息与⽇后可能提出的法律主张有关的时间长短</div>
    <div class="">• 其他适用的法律、会计、报告或监管规定的某些记录必须保存多长时间的要求</div>

    <div class="title">5 您的权利</div>
    <div class="">在您使用APP期间，您可以通过以下方式访问并管理您的个⼈信息：</div>
    <div class="second-title">5.1 访问和更正您的个⼈信息</div>
    <div class="">在松果Cam APP中查询和更正您的松果账户信息以及您提供的个⼈信息，包括昵称、设备名称、账号密码等。</div>

    <div class="second-title">5.2 删除您的个⼈信息</div>
    <div class="">以下情况发生时，您有权要求蜂巢物联删除您的个⼈信息：</div>
    <div class="">• 我们没有征求您的明确同意，收集了您的个⼈信息。</div>
    <div class="">• 我们处理您的个⼈信息违反了法律法规要求。</div>
    <div class="">• 我们违反了与您的约定来使用和处理您的个⼈信息。</div>
    <div class="">• 您注销了松果账号、卸载或者不再使用我们的产品（或服务）。</div>
    <div class="">• 我们停⽌对您提供服务</div>

    <div class="second-title">5.3 注销您的个⼈账户</div>
    <div class="">
      您可通过松果Cam
      APP随时注销您的松果账户。请您注意，如果您选择注销松果账户，那么您的松果账号将不可被使用且相关账号信息将被清除，您将无法再通过松果账号登录并使用松果的产品功能和服务（但不会影响您使用无需账号登录即可使用的服务和功能）。
    </div>
    <div class="">
      当您注销账户后，除法律法规要求我们保存相关信息的情况外，我们将停⽌为您提供相应的产品（或服务），并在15个⼯作⽇内删除或匿名化您的个⼈信息。
    </div>
    <div class="second-title">5.4 撤回您的同意或授权</div>
    <div class="">在APP中，您可以通过以下方式撤回您对我们的同意和授权。</div>
    <div class="">(1) 您可以通手机本地“设置->权限管理->松果智能摄像机”进⼊您对松果智能摄像机授权的权限管理界面，撤回对松果Cam
      APP的授权和同意。
    </div>
    <div class="">(2)通过本政策的“1
      我们收集哪些信息以及如何使用信息”模块的功能描述中的具体关闭路径和方式，关闭相关功能或解绑智能硬件设备。关闭即撤回对该功能相对应的授权。
    </div>
    <div class="">
      请您理解，每项业务功能需要⼀些基本的个⼈信息才能得以完成（见本政策第⼀节），当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理相应的个⼈信息。但您撤回同意的决定，不会影响此前基于您的授权⽽开展的个⼈信息处理。
    </div>
    <div class="second-title">5.5 响应您的上述请求</div>
    <div class="">
      为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。但请您理解，基于法律法规要求、保障信息安全、技术限制等原因，您的部分信息可能无法访问、修改和删除。
    </div>

    <div class="title">6 未成年⼈个⼈信息保护</div>
    <div class="">
      松果智能摄像机并⾮专门针对未成年⼈设计。不满⼗⼋周岁的未成年⼈在使用松果智能摄像机时，需要未成年⼈本⼈或者监护⼈同意和授权。不满⼗四周岁的儿童在使用松果智能摄像机时，需要儿童父母等监护⼈阅读本政策，儿童应当在提交个⼈信息之前寻求父母等监护⼈的同意和指导。若您是儿童的父母或者监护⼈，当您对您所监护的儿童的个⼈信息有疑问时，您可以通过本政策联系方式与我们联系。
    </div>

    <div class="title">7 本政策如何更新</div>
    <div class="">
      我们会根据业务和技术的变更，根据适用法律，依循良好做法，定期审核隐私政策，并可能更新本隐私政策。如果我们对本隐私政策进行重⼤变更，我们将通过您登记的联系方式如电子邮件（向您帐号指定的邮箱地址发送）或通过APP通知您，这样您可以了解我们收集的信息以及我们如何使用这些信息。此类隐私政策变化将从通知或网站规定的生效⽇期开始适用。我们建议您定期查阅本页面以获取我们隐私权实践的最新信息。您继续使用产品和服务，将被视为接受更新的隐私政策。在我们向您收集更多的个⼈信息或我们因为新的目的使用或披露您的个⼈信息时，我们会根据适用法律要求，再次征得您的同意。
    </div>

    <div class="title">8 联系我们</div>
    <div class="">
      如果您对我们的隐私政策及对您个⼈信息的处理有任何疑问、意见、建议或投诉，您可以通过发送邮件至
      <label class="link">kefu@bw30.com</label>
      ，随时与我们联系。在⼀般情况下，我们会在15个⼯作⽇内对您的请求予以答复。
    </div>
    <div class="">
      如果您对我们的回复不满意，特别是您认为我们的个⼈信息处理行为损害了您的合法权益，您还可以通过向北京市海淀区人民法院提起诉讼来寻求解决方案。
    </div>
  </div>
</template>

<script>
export default {
  name: '',

  components: {},

  setup() {
    const limitOptions = [
      {authName: '电话权限', funcName: '核心功能', purpose: '用于风控和保障运营安全', isNotice: '是', canClose: '是'},
      {
        authName: '位置权限',
        funcName: '核心功能',
        purpose: '获取您所在位置的WiFi网络列表，实现移动端查看您的智能硬件设备',
        isNotice: '是',
        canClose: '是'
      },
      {
        authName: '存储权限',
        funcName: '核心功能',
        purpose: '智能硬件设备拍录的视频、图像在手机本地的存储',
        isNotice: '是',
        canClose: '是'
      },
      {
        authName: '相机权限',
        funcName: '附加功能',
        purpose: '通过扫描二维码添加、分享智能硬件设备',
        isNotice: '是',
        canClose: '是'
      },
      {authName: '麦克风权限', funcName: '附加功能', purpose: '对讲通话', isNotice: '是', canClose: '是'},
      {authName: '联系人权限', funcName: '附加功能', purpose: '分享给用户选定的联系人', isNotice: '是', canClose: '是'}
    ]
    return {
      limitOptions
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  font-size: 14px;
  color: #666;
  padding: 40px 20px;
  line-height: 24px;
  text-align: left;
}

div + div {
  margin-top: 10px;
}

.head-title {
  font-size: 28px;
  color: #000;
  font-weight: bold;
  line-height: 80px;
}

.title {
  font-size: 24px;
  color: #000;
  font-weight: bold;
  padding: 30px 0;
  margin-bottom: 20px;
  border-bottom: 2px solid #bbb;
}

.second-title {
  font-size: 20px;
  color: #000;
  font-weight: bold;
}

.third-title {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.import-title {
  color: #000;
  font-weight: bold;
}

.link {
  color: #3c9cff;
}

.grid-table {
  font-size: 12px;

  div {
    display: flex;
    align-items: inherit;
    justify-content: space-between;
    border: 1px solid #ddd;

    label {
      flex: 3;
      padding: 0 3px;
      display: inline-block;
    }

    label + label {
      border-left: 1px solid #ddd;
    }

    .col-span {
      flex: 1;
    }
  }

  div + div {
    margin: 0;
    border-top: none;
  }

  .head-th {
    color: #000;
    text-align: center;
  }
}
</style>
